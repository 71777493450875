@media screen and (max-width: 400px) {
  #features {
    padding: 20px;
    width: 111%;
  }
  #about,
  #services,
  #testimonials,
  #team,
  #contact,
  #footer {
    width: 111%;
  }
  #portfolio {
    width: 110%;
  }
}

.container {
  background-color: rgba(255, 255, 255, 0.25);
}